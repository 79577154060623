// @flow
import { useState, useEffect } from "react";

export default function useScript(src: string) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onScriptLoad = () => {
      setReady(true);
    };

    script.addEventListener('load', onScriptLoad);

    if (document.body) {
      document.body.appendChild(script);
    }

    return () => {
      script.removeEventListener('load', onScriptLoad);
    };
  }, [src]);

  return [ready];
}
