// @flow
import React, { useCallback, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Radio, { NativeRadioControl } from '@material/react-radio';
import ky from 'ky';
import SmartPaymentButtons, { PayPalSDKWrapper } from 'react-smart-payment-buttons';

import '@material/react-radio/dist/radio.css';

import useScript from './hooks/useScript';

const {
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_STRIPE_KEY,
  REACT_APP_RECAPTCHA_KEY,
  REACT_APP_STRIPE_BACKEND_FN_URL,
  REACT_APP_PAYPAL_BACKEND_FN_URL,
  REACT_APP_PAYMENT_SUCCESS_URL,
  REACT_APP_PAYMENT_CANCEL_URL,
} = process.env;

if (
  !REACT_APP_PAYPAL_CLIENT_ID
  || !REACT_APP_STRIPE_KEY
  || !REACT_APP_RECAPTCHA_KEY
  || !REACT_APP_STRIPE_BACKEND_FN_URL
  || !REACT_APP_PAYPAL_BACKEND_FN_URL
  || !REACT_APP_PAYMENT_SUCCESS_URL
  || !REACT_APP_PAYMENT_CANCEL_URL
) {
  throw new Error('Required env variables are missing.');
}

const isDev = process.env.NODE_ENV !== 'production';

const Form = styled.form`
  max-width: 600px;

  ${props => !props['data-in-frame'] && css`
    margin: 0 auto;
    padding: 50px 20px;
  `}
`;

const Heading = styled.h3`
  margin: 1rem 0 0.5rem;
  font-weight: 500;
  font-size: 1.125rem;
  color: #58468c;
`;

const Fieldset = styled.fieldset`
  margin: 0 0 1rem;
  padding: 0;
  border: 0;
  box-shadow: none;
  outline: 0;
`;

const TextField = styled.input`
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  border: 0;
  box-shadow: 0 1px 0 #888;
  outline: 0;
  background: none;
  transition: box-shadow 0.2s ease-in;

  &:hover,
  &:focus {
    outline: 0;
  }

  &:focus {
    box-shadow: 0 2px 0 #58468c;
  }

  &::placeholder {
    color: #ccc;
  }
`;

const CustomAmount = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const CustomAmountInput = styled(TextField)`
  width: 70px;
  vertical-align: middle;
`;

const PayPalButtonsWrapper = styled.div`
  max-width: 240px;
`;

const DonateButton = styled.button`
  margin-bottom: 0.625rem;
  padding: 0 2.25rem;
  line-height: 3rem;
  font-weight: bold;
  background: #ff8a73;
  color: #fff;
  border: 0;
  border-radius: 1.5rem;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  margin: 1rem 0;
  color: #d32f2f;
`;

const Notes = styled.div`
  color: #8b869a;
`;

const amountOptions = {
  oneTime: ['10', '50', '100', '500', '1000'],
  monthly: ['5', '10', '20', '50', '100'],
};

const redirectTo = (url: string) => {
  if (window.parent === window) {
    window.location.href = url;
  } else {
    window.parent.postMessage(url, 'https://plumvillage.app');
  }
};

type DonationType = 'oneTime' | 'monthly';
type PaymentMethod = 'PayPal' | 'Card';

function App() {
  const [donationType, setDonationType] = useState<DonationType>('monthly');
  const [amountOption, setAmountOption] = useState<string>('10');
  const [customAmount, setCustomAmount] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('Card');
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [googleCaptchaReady] = useScript(`https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY || ''}`);
  const [stripeReady] = useScript('https://js.stripe.com/v3/');

  const isInFrame = window.parent !== window;

  const donationAmount = useMemo(
    () => amountOption === 'custom' ? customAmount : amountOption,
    [amountOption, customAmount],
  );

  const handleFormSubmit = useCallback((event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  const handleDonationTypeChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const newDonationType = event.currentTarget.value === 'oneTime' ? 'oneTime' : 'monthly';
    if (newDonationType !== donationType) {
      setErrorMessage('');
      if (amountOption !== 'custom') {
        setAmountOption(amountOptions[newDonationType][1]);
      }
      setDonationType(newDonationType);
    }
  }, [amountOption, donationType]);

  const handlePaymentMethodChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const newPaymentMethod = event.currentTarget.value === 'Card' ? 'Card' : 'PayPal';
    if (newPaymentMethod !== paymentMethod) {
      setErrorMessage('');
      setPaymentMethod(newPaymentMethod);
    }
  }, [paymentMethod]);

  const handleAmountOptionChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    if (event.currentTarget && event.currentTarget.value && event.currentTarget.value !== amountOption) {
      setCustomAmount('');
      setAmountOption(event.currentTarget.value);
    }
  }, [amountOption]);

  const handleCustomAmountChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      if (amountOption !== 'custom') {
        setAmountOption('custom');
      }
      setCustomAmount(event.currentTarget.value.replace(/[^\d]/, ''));
    }
  }, [amountOption]);

  const handleCreatePayPalOrder = useCallback((data, actions) => {
    try {
      setErrorMessage('');

      return actions.order.create({
        purchase_units: [{
          amount: {
            value: donationAmount,
            description: 'Donation',
          },
        }],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      });
    } catch (error) {
      if (isDev) {
        console.error(error);
      }
      setErrorMessage(`Oops! Something went wrong. Please try again.`);
    }
  }, [donationAmount]);

  const processPayPalSubscription = useCallback(async () => {
    try {
      setRedirecting(true);
      setErrorMessage('');

      if (!googleCaptchaReady) {
        return;
      }

      const amount = Number.parseInt(donationAmount, 10);
      const token = await window.grecaptcha.execute(REACT_APP_RECAPTCHA_KEY, { action: 'get_paypal_plan_id' });
      const subscriptionRedirectUrl = await ky.post(REACT_APP_PAYPAL_BACKEND_FN_URL, {
        json: {
          amount,
          token,
          successUrl: REACT_APP_PAYMENT_SUCCESS_URL,
          cancelUrl: REACT_APP_PAYMENT_CANCEL_URL,
        },
        timeout: 60000,
      }).text();

      if (
        (isDev && subscriptionRedirectUrl.startsWith('https://www.sandbox.paypal.com'))
        || (!isDev && subscriptionRedirectUrl.startsWith('https://www.paypal.com'))
      ) {
        redirectTo(subscriptionRedirectUrl);
      } else {
        throw new Error(`Invalid PayPal subscription URL: ${subscriptionRedirectUrl}`);
      }
    } catch (error) {
      if (isDev) {
        console.error(error);
      }
      setErrorMessage(`Oops! Something went wrong. Please try again.`);
    }
    setRedirecting(false);
  }, [donationAmount, googleCaptchaReady]);

  const handleApprove = useCallback((data, actions) => actions.order.capture().then(() => {
    redirectTo(REACT_APP_PAYMENT_SUCCESS_URL);
  }), []);

  const processWithStripe = useCallback(async () => {
    try {
      setRedirecting(true);
      setErrorMessage('');

      const amount = Number.parseInt(donationAmount, 10);
      const token = await window.grecaptcha.execute(REACT_APP_RECAPTCHA_KEY, { action: 'process_with_stripe' });
      const sessionId = await ky.post(REACT_APP_STRIPE_BACKEND_FN_URL, {
        json: {
          amount,
          token,
          donationType,
          successUrl: REACT_APP_PAYMENT_SUCCESS_URL,
          cancelUrl: REACT_APP_PAYMENT_CANCEL_URL,
        },
        timeout: 60000,
      }).text();
      var stripe = window.Stripe(REACT_APP_STRIPE_KEY);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      if (isDev) {
        console.error(error);
      }
      setErrorMessage(`Oops! Something went wrong while redirecting to checkout. Please try again.`);
    }
    setRedirecting(false);
  }, [donationAmount, donationType]);

  const donateButtonText = redirecting
    ? 'redirecting...'
    : `Donate $${donationAmount}${donationType === 'monthly' && donationAmount ? ' per month' : ''}`;

  return (
    <Form data-in-frame={isInFrame || undefined} onSubmit={handleFormSubmit}>
      <Fieldset>
        <Heading>Donation Type</Heading>
        <Radio label="Monthly" key="monthly">
          <NativeRadioControl
            id="donation-type-monthly"
            name="donation-type"
            value="monthly"
            disabled={redirecting}
            onChange={handleDonationTypeChange}
            checked={donationType === 'monthly'}
          />
        </Radio>
        <Radio label="One time" key="one-time">
          <NativeRadioControl
            id="donation-type-one-time"
            name="donation-type"
            value="oneTime"
            disabled={redirecting}
            onChange={handleDonationTypeChange}
            checked={donationType === 'oneTime'}
          />
        </Radio>
      </Fieldset>
      <Fieldset>
        <Heading>Amount</Heading>
        {amountOptions[donationType].map((amount) => (
          <Radio label={`$${amount}`} key={`${donationType}-${amount}`}>
            <NativeRadioControl
              id={`radio-amount-${amount}`}
              name="amount"
              value={amount}
              disabled={redirecting}
              onChange={handleAmountOptionChange}
              checked={amountOption === amount}
            />
          </Radio>
        ))}
        <CustomAmount>
          <Radio label="$" key="custom-amount">
            <NativeRadioControl
              id="radio-custom-amount"
              name="amount"
              value="custom"
              disabled={redirecting}
              onChange={handleAmountOptionChange}
              checked={!amountOptions[donationType].includes(amountOption)}
            />
          </Radio>
          <CustomAmountInput
            type="number"
            value={customAmount}
            placeholder="Other"
            disabled={redirecting}
            onChange={handleCustomAmountChange}
          />
        </CustomAmount>
      </Fieldset>
      <Fieldset>
        <Heading>Payment Method</Heading>
        <Radio label="Card" key="Card">
          <NativeRadioControl
            id="radio-payment-method-card"
            name="payment-method"
            value="Card"
            disabled={redirecting}
            onChange={handlePaymentMethodChange}
            checked={paymentMethod === 'Card'}
          />
        </Radio>
        <Radio label="PayPal" key="PayPal">
          <NativeRadioControl
            id="radio-payment-method-PayPal"
            name="payment-method"
            value="PayPal"
            disabled={redirecting}
            onChange={handlePaymentMethodChange}
            checked={paymentMethod === 'PayPal'}
          />
        </Radio>
      </Fieldset>
      {paymentMethod === 'PayPal' && donationType === 'oneTime' && (
        <PayPalSDKWrapper
          clientId={REACT_APP_PAYPAL_CLIENT_ID}
          vault="true"
          loading="loading..."
        >
          <PayPalButtonsWrapper>
            <SmartPaymentButtons
              key={`${donationType}-${donationAmount}`}
              style={{
                layout: 'horizontal',
                size: 'small',
                shape: 'pill',
                label: 'pay',
                height: 48,
                tagline: false,
              }}
              createOrder={handleCreatePayPalOrder}
              onApprove={handleApprove}
            />
          </PayPalButtonsWrapper>
        </PayPalSDKWrapper>
      )}
      {(paymentMethod === 'Card' || donationType === 'monthly') && (
        <DonateButton
          type="button"
          disabled={!donationAmount || !googleCaptchaReady || !stripeReady || redirecting}
          onClick={paymentMethod === 'PayPal' ? processPayPalSubscription : processWithStripe}
        >
          {donateButtonText}
        </DonateButton>
      )}
      {errorMessage.length > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Notes>
        <p>
          You will be redirected to a secure page to enter your payment details.
          We will not share any details you enter with any third parties.
        </p>
        <p>
          <>This site is protected by reCAPTCHA and the Google </>
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          <> and </>
          <a href="https://policies.google.com/terms">Terms of Service</a>
          <> apply.</>
        </p>
      </Notes>
    </Form>
  );
}

export default App;
